import React from 'react';
import Layout from '../components/Layout';

const NotFoundPage = () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <div className="columns is-centered">
            <div className="column is-full">
              <h1 className="title is-size-2 is-family-secondary has-text-info has-text-centered">
                I&#39;m sorry, we could find that page
              </h1>
              <p className="is-size-5 has-text-centered">
                You found a page that doesn&#39;t exist... please go to the home page and try again.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
